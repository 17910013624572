/*!
 * Main Custom Script File for Default Demos
 * Project : Xapp - HTML App Landing Page Template
 * Version : 1.0
 * Author: Norhan_Moh
 * Primary use : Templates / Creative / Portfolio / Business /Mobile /App

 * Table Content :
  -----------------
  + Loader
  + Bootstrap ToolTips
  + Blog Sidebar Categories collapsed
  + Setting  Intro Home section's Height
  + Fixed position for the navbar where is at home Page
  + Modals Function
  + Contact Form Validation
  + Scroll To Top
  + Scroll to Anchors
  + Delaying a hyperlink  for loading screen
  + Add Active class to the header links
  + Contact Form AJAX
  + wayPoints plugin function
  + CountDown Script 
  + Show / Hide Navbar
  + Show / Hide Top Btn

  * Plugins :
  ------------
  + CountDown Script ---- > inspired from : https://www.w3schools.com/howto/howto_js_countdown.asp
  + Ajax Contact Form ---- > inspired from : http://blog.teamtreehouse.com/create-ajax-contact-form
  + Waypoints - 4.0.1 ---- > https://github.com/imakewebthings/waypoints/blob/master/licenses.txt
  + jQuery countTo Plugin  ---- > https://github.com/mhuggins/jquery-countTo


 */

/*jshint strict:false */
/*jslint browser: true*/
/*jslint node: true */
/*global $, jQuery, alert*/
/*global alert: false, console: false, jQuery: false */

'use strict';

/* Vars */

var
    // Scrolling to Anchors
    target = '',

    // Loading to Anchors
    hrefLink = '',

    // Show / Hide Navbar
    currentScrollValue = 0,
    previousScrollValue = 0,

    // Modals Function
    videoLink = '',
    imgSrc = '',

    // Contact Form AJAX
    form = '',
    formData = '',
    formMessages = '',

    //Add Active class 
    url = '',
    pageName = '',

    // wayPoints plugin function
    sections = [],

    // The function of countDown
    targetDate = '';

// Loader
$(window).on('load', function () {
    setTimeout(function () {
        $('#loader').addClass('hide');
        $("main:not(.home)").addClass("show");
        $("main.home section#intro .container .row,main.home section#intro ").addClass("show");
    }, 200);
});


$(document).ready(function () {
    // Bootstrap ToolTips
    $('[data-toggle="tooltip"]').tooltip();

    // Blog Sidebar Categories collapsed
    (function () {
        $(".categories a[data-toggle=collapse]").on("click", function () {
            $(this).parents("li").siblings("li").find(".collapse").collapse("hide");
        });
    })();


    // Intro Home section's Height
    (function () {
        $("#intro").css("height", $(window).height());
        $(window).resize(function () {
            $("#intro").css("height", $(window).height());

        });
    })();
    // Fixed position for the navbar where is at home Page
    (function () {
        if ($('main').hasClass('home')) {

            $('#navbar').css('display', 'none');
        } else {
            $('#navbar').css('display', '');
        }
    })();

    // Modals Function
    (function () {
        // Video

        $("#video [data-video-link*='.']").on('click', function () {
            videoLink = $(this).attr('data-video-link');
            $('#video-modal iframe').attr('src', videoLink);
            $('#video-modal').modal();
        });

        // Image
        $('.zoomIn-img img').on('click', function () {
            imgSrc = $(this).attr('src');
            $('#image-modal img').attr('src', imgSrc);
            $('#image-modal').modal();

        });
    })();

    // Contact Form Validation
    (function () {
        window.addEventListener('load', function () {
            // Fetch all the forms we want to apply custom Bootstrap validation styles to
            var forms = document.getElementsByClassName('needs-validation');
            // Loop over them and prevent submission
            var validation = Array.prototype.filter.call(forms, function (form) {
                form.addEventListener('submit', function (event) {
                    if (form.checkValidity() === false) {
                        event.preventDefault();
                        event.stopPropagation();
                    }
                    form.classList.add('was-validated');
                }, false);
            });
        }, false);
    })();

    // Scroll To Top
    (function () {
        $('#top-btn').on('click', function () {
            $('html,body').stop(true, false).animate({
                scrollTop: 0
            }, 2000);
        });
    })();

    //  Scroll to Anchors
    (function () {
        $('a[href*="#"]:not([href="#"]):not([href="#!"]):not([class*="control"]):not([class*="modal"]):not([data-toggle="collapse"]):not([href*="modal"])').on('click', function () {
            target = $(this.hash);
            $('html, body').animate({
                scrollTop: $(target).offset().top
            }, 1000);
        });
        $("header a:not(.dropdown-toggle) ").on('click', function () {
            $('header a:not(.dropdown-item)').removeClass('active');
            $(this).addClass('active');
            $('.navbar-collapse').collapse('hide');

        });
    })();

    // Delaying a hyperlink  for loading screen
    (function () {
        $('a[href*=".html"],a[href*="http"],#errorPage button').on('click', function (e) {
            e.preventDefault();
            hrefLink = $(this).attr('href');

            if ($("main").hasClass("show")) {
                $('main').removeClass('show');
                setTimeout(function () {
                    $('#loader').removeClass('hide');
                }, 600);
                setTimeout(function () {
                    window.location = hrefLink;
                }, 1000);
            } else {
                setTimeout(function () {
                    window.location = hrefLink;
                }, 1000);
                $('#loader').removeClass('hide');
            }

        });
    })();
    // Add Active class to the header links
    (function () {
        // get url address
        url = window.location.pathname.split('/');
        pageName = url[url.length - 1]; // get last part of it

        // set the active class
        $("header a[ href*='" + pageName + "']").addClass("active");
        $("header .dropdown-item.active").parent(".dropdown-menu").siblings(".dropdown-toggle").addClass("active");

    })();

    // Contact Form AJAX
    (function () {
        form = $('.contact-form form');
        formData = '';
        formMessages = $('.contact-form .form-messages .alert');
        $(form).submit(function (e) {
            e.preventDefault();
            // Serialize the form data.
            formData = $(form).serialize();
            $.ajax({
                type: 'POST',
                url: $(form).attr('action'),
                data: formData
            }).done(function (response) {
                // Make sure that the formMessages div has the 'success' class.
                $(formMessages).removeClass('alert-danger');
                $(formMessages).addClass('alert-success');


                // Set the message text.
                $(formMessages).text(response);

                // Clear the form.
                $('form').trigger('reset');
                $(form).removeClass('was-validated');

                //Show the Message

                $(formMessages).slideDown("slow");
                $('html, body').animate({
                    scrollTop: $(".form-messages").offset().top
                }, 1000);
                setTimeout(function () {
                    $(formMessages).slideUp();
                    $(formMessages).removeClass('alert-danger ,alert-success');
                    $(formMessages).text(' ');
                }, 50000);
            }).fail(function (data) {
                // Make sure that the formMessages div has the 'error' class.
                $(formMessages).removeClass('alert-success,');
                $(formMessages).addClass('alert-danger');

                // Set the message text.
                if (data.responseText !== '') {
                    $(formMessages).text(data.responseText);
                } else {
                    $(formMessages).text('Oops! An error occured and your message could not be sent.');
                }

                //Show the Message


                $(formMessages).slideDown("slow");
                $('html, body').animate({
                    scrollTop: $(".form-messages").offset().top
                }, 1000);
                setTimeout(function () {
                    $(formMessages).slideUp();
                    $(formMessages).removeClass('alert-danger ,alert-success');
                    $(formMessages).text(' ');
                }, 50000);
            });
        });
    })();
    // wayPoints plugin function
    (function () {
        if ($("main").is(".home")) {
            //  jQuery CountTo plugin 
            var waypoint = new Waypoint({
                element: document.getElementById('statistics'),
                offset: "45%",
                handler: function (direction) {

                    $('.counter').countTo({
                        speed: 2000 // Speed of the counter
                    });
                    this.destroy();
                }
            });
            //  Show the statistics section's img
            var waypoint = new Waypoint({
                element: document.getElementById('statistics'),
                offset: "45%",
                handler: function (direction) {
                    if (direction === 'down') {
                        $('#statistics img').addClass("show");
                    } else {
                        $('#statistics img').removeClass("show");
                    }

                }
            });

            // Show / Hide contactInfo img
            var waypoint = new Waypoint({
                element: document.getElementById('contactInfo'),
                offset: "45%",
                handler: function (direction) {
                    if (direction === 'down') {
                        $('#contactInfo img').addClass("show");
                    } else {
                        $('#contactInfo img').removeClass("show");
                    }

                    // this.destroy();
                }
            });
            // Animated home sections
            sections = $(".home section:not(#intro) .container .row");
            sections.each(function (i) {
                new Waypoint({
                    element: this,
                    offset: '55%',
                    handler: function () {
                        $(sections[i]).addClass("show");

                    }
                });
            });
        }
    })();
    // CountDown Script from W3C School for Coming Soon
    (function () {
        // The function of countDown
        function countDown(targetDate) {

            // Set the date we're counting down to
            var countDownDate = new Date(targetDate).getTime();

            // Update the count down every 1 second
            var x = setInterval(function () {

                // Get todays date and time
                var now = new Date().getTime();

                // Find the distance between now an the count down date
                var distance = countDownDate - now;

                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                // Output the result 
                $("#countDown .days strong").text(days);
                $("#countDown .hours strong").text(hours);
                $("#countDown .minutes strong").text(minutes);
                $("#countDown .seconds strong").text(seconds);

                // If the count down is over, write some text 
                if (distance < 0) {
                    clearInterval(x);
                    document.getElementById("countDown").innerHTML = "EXPIRED";
                }
            }, 1000);
        }
        //get the target date from HTML custom attribute
        targetDate = $("#comingSoon #countDown").attr("data-targetDate");
        //Call the function
        countDown(targetDate);
    })();

    $(window).scroll(function () {

        // Navbar Scrolling Action
        (function () {
            currentScrollValue = $(window).scrollTop();
            // For Particels demos 
            if (currentScrollValue > 300) {
                $(' #navbar').addClass("scrolling");
            } else {
                $(' #navbar').removeClass("scrolling");

            }
            // Show / Hide Navbar
            if ($('main').hasClass('home')) {
                if (currentScrollValue > previousScrollValue) {
                    $(' #navbar').slideUp();
                } else {
                    if (currentScrollValue < 400) {
                        $(' #navbar').slideUp();
                    } else {
                        $(' #navbar').slideDown();
                    }
                }
            } else {
                currentScrollValue = $(window).scrollTop();

                if (currentScrollValue > previousScrollValue) {
                    $(' #navbar').slideUp();
                } else {

                    $(' #navbar').slideDown();

                }
            }
            previousScrollValue = currentScrollValue;

        })();


        // Show / Hide Top Btn

        (function () {
            if ($(window).scrollTop() > 1000) {
                $('#top-btn').addClass('show');
            } else {
                $('#top-btn').removeClass('show');
            }
        })();

    });
});